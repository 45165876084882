import { Box, enerbitColors, Typography } from "@enerbit/base";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ResultsSkeleton from "../../components/Results/ResultsSkeleton";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ResultsGrid from "../../components/Results/ResultsGrid";
import SearchBox from "../../components/Search/SearchBox";
import Error from "../../components/Results/Error";
import EmptyResults from "../../components/Results/EmptyResults";

const Results = () => {
    const { loading, error, results, query } = useSelector(
        (state: RootState) => state.search,
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (!query) {
            navigate("/");
        }
    }, []);

    return (
        <Box sx={{ mx: "-50px", my: "-40px" }}>
            <SearchBox customsStyles={{ borderRadius: "unset" }} />
            <Box sx={{ py: "15px", px: "50px" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                    }}
                >
                    <Typography
                        sx={{
                            color: enerbitColors.primary.main,
                            fontSize: "28px",
                        }}
                    >
                        Resultados
                    </Typography>
                </Box>
                {loading && <ResultsSkeleton />}
                {!loading && !error && results.length > 0 && <ResultsGrid />}
                {!loading && !error && results.length === 0 && <EmptyResults />}
                {!loading && error && <Error />}
            </Box>
        </Box>
    );
};

export default Results;
